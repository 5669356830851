.skills_img__card .circular__cover {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #edf2f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills_img__card img {
  width: 42px;
}

.skills_img__card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
