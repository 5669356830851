.skills_section {
  background-color: #fff;
  padding: 40px;
}

.skills_exp_container {
  display: grid;
  grid-template-columns: 600px 300px;
  place-content: center;
  column-gap: 30px;
}

.skills_img_cards_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 20px;
}

.skills_experience_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.experience_card {
  display: grid;
  grid-template-columns: 60px auto;
  box-shadow: -0.4rem 0.5rem 1.2rem rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 4px;
  align-items: center;
  transition: all 0.3s;
}

.experience_card:hover {
  background-color: #748ffc;
}

.experience_card:hover .exp__year {
  color: #000;
}

.experience_card:hover .exp_company {
  color: #000;
}

.role_company_box {
  display: flex;
  flex-direction: column;
}

.exp__year {
  color: #748ffc;
  font-size: 20px;
}

.exp_role {
  font-size: 20px;
}

.exp_company {
  color: #777;
}

@media (max-width: 992px) {
  .skills_exp_container {
    grid-template-columns: 500px 300px;
  }
}

@media (max-width: 900px) {
  .skills_exp_container {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .skills_exp_container .experience_card {
    width: 400px;
  }

  .skills_experience_container {
    place-content: center;
    display: grid;
  }
}

@media (max-width: 450px) {
  .skills_img_cards_container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .skills_exp_container .experience_card {
    width: 340px;
  }
}

@media (max-width: 370px) {
  .skills_img_cards_container {
    column-gap: 10px;
  }

  .skills_exp_container .experience_card {
    width: 300px;
  }
}
