.row_card_section {
  background-color: #fff;
}
.row_card_section .btn {
  width: 200px;
}

.about__image {
  display: grid;
  place-content: center;
}

.about__image img {
  width: 100%;
  height: auto;
}

.about_slider__continer {
  height: 400px;
  width: 500px;
}

.about_slider__continer .image__card_container img {
  height: 400px;
  width: 100%;
}

@media (max-width: 576px) {
  .about_slider__continer {
    height: 300px;
    width: 360px;
  }

  .about_slider__continer .image__card_container img {
    height: 300px;
    width: 100%;
  }
}

@media (max-width: 380px) {
  .about_slider__continer {
    height: 280px;
    width: 320px;
  }

  .about_slider__continer .image__card_container img {
    height: 280px;
    width: 100%;
  }
}
