.hero_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 40px;
  min-height: 90vh;
}

.user__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.user__content .btn {
  width: 180px;
}

.user__image {
  display: flex;
  justify-content: center;
}

.user__image img {
  width: 65%;
  height: auto;
}

@media (max-width: 1350px) {
  .user__image img {
    width: 75%;
  }
}

@media (max-width: 1200px) {
  .user__image img {
    width: 85%;
  }
}

@media (max-width: 992px) {
  .hero_section {
    grid-template-columns: 1fr;
  }

  .user__image img {
    width: 50%;
  }

  .user__image {
    grid-row-start: 1;
    grid-row-end: 2;
  }
}

@media (max-width: 500px) {
  .user__image img {
    width: 85%;
  }
  .hero_section {
    padding: 20px;
  }
}
