.header {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  position: relative;
}

.logo_container {
  /* font-family: "Tangerine", cursive; */
  /* font-family: "Italianno", cursive; */
  font-family: "Great Vibes", cursive;
  color: #748ffc;
}

.nav__link_container {
  display: flex;
  gap: 10px;
}

.nav__link_container li {
  list-style: none;
}

.nav__links {
  text-decoration: none;
  color: #777;
  transition: all 0.3s;
  font-weight: 400;
}

.nav__links:hover {
  background-color: #748ffc;
  color: #fff;
  padding: 4px 10px;
  border-radius: 4px;
}

.burger {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  display: none;
}

.burger .line {
  height: 3px;
  width: 30px;
  background-color: #000;
}

.toggle .line {
  transition: all 0.3s;
}
.toggle .line__1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .line__2 {
  opacity: 0;
}
.toggle .line__3 {
  transform: rotate(45deg) translate(-6px, -6px);
}

@media (max-width: 768px) {
  .burger {
    display: flex;
  }
  .nav__link_container {
    padding-top: 10px;
    position: absolute;
    right: 0;
    top: 10vh;
    width: 40%;
    height: 100vh;
    z-index: 100;
    background-color: #fff;
    flex-direction: column;
    align-items: center;
    box-shadow: 1rem 1.2rem 1.2rem rgba(0, 0, 0, 0.1);
    border-top: 2px solid #f9f9f9;
    transition: all 0.5s;
    transform: translateX(-350%);
  }

  .nav_active {
    transform: translateX(0%);
  }
}

@media (max-width: 576px) {
  .nav__link_container {
    width: 50%;
  }
}

@media (max-width: 400px) {
  .nav__link_container {
    width: 70%;
  }
}
