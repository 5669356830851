.gallery_section {
  padding: 40px;
}

.img_card {
  width: 100%;
  border-radius: 8px;
  height: 280px;
}

.image__card_container {
  padding: 0 20px;
}
